import styled from "styled-components";


//#region Styles
const Container = styled.div`
`;
const ImageDiv = styled.div`
  background-color:#dbdbdb ;
`;
const InformationDiv = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.divType === "Why" ? "column" : "row")};
  justify-content: ${(props) => (props.divType === "Why" ? "center" : "space-between")};
  align-items:${(props) => (props.divType === "Why" ? "center" : "flex-start")};
  padding: 2rem;
  text-align: ${(props) => (props.divType === "Why" ? "center" : "start")};
   
  font-family: "Raleway", sans-serif;
  background-color: ${(props) => (props.divType === "Why" ? "#f2f2f2" : "#ffffff")};
  & p {
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    line-height: 26px;
    color: #818181;
    font-weight: 400;
  }
  & p#p1 {
    font-size: 16px;
    font-weight: 600;
  }
  & h1 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
    
  & h2 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
  & h4 {
    color: #ffffff;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const DivContent = styled.div`
  width: 50%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;
const PicturesDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center ;
  background-color:#aa182c !important ;
  padding:1rem ;
`;
const Pictures = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  & img {
    margin:1rem ;
    width:28% ;
    @media only screen and (max-width: 768px) {
        margin:1rem ;
        width:95% ;
  }
  }
`;

//#endregion
function SolutionsContent() {
    const headerPhoto = "./catalog/Banners/solutions1.jpg";
    const firstDivPhoto = "./catalog/Banners/solutions3.jpg";
    const solutionspictures1 = "./catalog/Banners/solutionspictures1.jpg";
    const solutionspictures2 = "./catalog/Banners/solutionspictures2.jpg";
    const solutionspictures3 = "./catalog/Banners/solutionspictures3.jpg";

  const headerFirstDiv =
    "DRAGON LONDON: BEST FOODS & SERVICES UNDER ONE ROOF";
  const pharagraph1FirstDiv =
    "Are you looking for top-quality products and exceptional service for your shop? You’ve come to the right place. At Dragon London, we provide a wide range of premium products, helping you manage your stock and maximise the value of your business with our expert services.";
  const pharagraph2FirstDiv =
    "We are your one-stop shop, offering an extensive selection of food products, from meats and dairy to beverages, confectionery, and more, all designed to meet your customers' demands.";
  const pharagraph3FirstDiv =
    "Our vans are fully stocked with the best products and delivered straight to your door—but our service doesn’t stop there. We work closely with you to organise and optimise your orders based on your specific needs, ensuring your shelves are always well-stocked with high-demand items.";
  const pharagraph4FirstDiv =
    "Fill your store with a diverse range of high-quality products. Our dedication to providing outstanding service means we’re always ready to assist you in achieving the best results for your business. Your success is our priority, and we guarantee 100% satisfaction.";
  
    const pharagraph1DivContent =
    "We guarantee reliable deliveries directly to your shop. Our local deliveries are handled by a fleet of refrigerated vehicles, driven by experienced, trained drivers. We pay close attention to expiry dates and ensure that products are stored in optimal conditions to maintain freshness and quality. Cold storage in all our vehicles is a key part of our commitment to delivering the best products to you.";
  const pharagraph2DivContent =
    "For added convenience, you can book products in advance to ensure your orders are complete and timely. We also take direct orders during store visits, making the process even easier.";
    const pharagraph3DivContent =
    "Additionally, we offer assistance in preparing customised product packs for customers, ensuring you stock only the best-suited items for your market. Our knowledgeable staff is on hand to help you select products that attract new customers and boost your store’s profitability.";

  return (
    <Container>
        <ImageDiv>
        <Image
              alt=""
              loading="lazy"
              title="homepagebanner"
              src={headerPhoto} />
      </ImageDiv>
      <InformationDiv>
        <DivContent>
            <ImageDiv>
                <Image
                    alt=""
                    loading="lazy"
                    title="homepagebanner"
                    src={firstDivPhoto} />
            </ImageDiv>
        </DivContent>
        <DivContent>
          <h1>{headerFirstDiv}</h1><p>&nbsp;</p>
          <p>{pharagraph1FirstDiv}</p><p>&nbsp;</p>
          <p>{pharagraph2FirstDiv}</p><p>&nbsp;</p>
          <p>{pharagraph3FirstDiv}</p><p>&nbsp;</p>
          <p>{pharagraph4FirstDiv}</p><p>&nbsp;</p>
        </DivContent>
      </InformationDiv>
      <PicturesDiv>
        <Pictures>
          <Image src={solutionspictures1} alt="market" />
          <Image src={solutionspictures2} alt="market" />
          <Image src={solutionspictures3} alt="market" />
        </Pictures>
      </PicturesDiv>
      <InformationDiv divType={"Why"}>
        <DivContent>
          <h1>WHY CHOOSE US?</h1>
        </DivContent>
        <DivContent>
          <p>{pharagraph1DivContent}</p><p>&nbsp;</p>
          <p>{pharagraph2DivContent}</p><p>&nbsp;</p>
          <p>{pharagraph3DivContent}</p>
        </DivContent>
      </InformationDiv>
    </Container>
  );
}

export default SolutionsContent;