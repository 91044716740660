import { Button, ButtonGroup } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import styled from "styled-components";
import { useState, useEffect, useRef, forwardRef } from "react";
import { colors } from "../../res/values/values";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../redux/actions/cartActions";
import * as productActions from "../../redux/actions/productActions";
import CartAccordionMenu from "../MenuComponents/CartAccordionMenu";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import OrcaModal from "../Modal/OrcaModal";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import { checkBlockedUnit,initialUnit,checkDeliveryDate } from "../../utilities/helpers";

//001 ++ Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
import FavoriteIcon from '@mui/icons-material/FavoriteBorder';
import SelectedFavoriteIcon from '@mui/icons-material/Favorite';
//001 -- Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
//#region styles

const Container = styled.div`
  margin: 20px;
 
`;
const DDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Row = styled.div`
  border-bottom: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
const Row2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
const Row3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
const Image = styled.img`
  height: 18rem;
  object-fit: contain;
  padding: 1vw;
  @media only screen and (min-width: 769px) {
    width: 30%;
  }
`;

const Details = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 1vw;
  width: 100%;
  @media only screen and (min-width: 769px) {
    text-align: start;
    align-items: flex-start;
  }
`;

const Title = styled.span`
  font-size: 1.4rem;
  color: black;
  font-weight: 600;
  padding: 5px;
  margin-bottom: 10px;
  @media only screen and (min-width: 769px) {
    font-size: 1.8rem;
  }
`;
const Text = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
  padding: 2px;
  padding-right: 10px;
  border-width: 0px 0px 0px 0px;
  border-color: lightgray;
  border-style: solid;
  @media only screen and (min-width: 769px) {
    font-size: 0.8rem;
    margin-left: 10px;
  }
`;
const BreadCrumbText = styled.a`
  font-size: 0.8rem;
  font-weight: 600;
  color: ${colors.secondryColor};
  padding: 5px;
  border-width: 0px 0px 1px 0px;
  border-color: lightgray;
  border-style: solid;
  @media only screen and (min-width: 768px) {
    border-width: 0px 1px 0px 0px;
    padding: 2px;
    margin-left: 10px;
    padding-right: 10px;
  }
`;

const Price = styled.h4`
  margin-top: 10px;
  color: ${colors.primaryColor};
  padding: 5px;
  font-size: 1.3rem;
  @media only screen and (min-width: 769px) {
    font-size: 1.5rem;
    margin-left: 10px;
    text-align: left;
  }
`;
const PriceDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
const PriceDiv2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const ColPrice = styled.span`
  text-align: left;
  padding: 1px;
  font-size: 1.2em;
  font-weight: ${(props) =>
    props.deliveryMethod === "Collection" ? 600 : 500};
  color: ${(props) =>
    props.deliveryMethod === "Collection"
      ? colors.blackColor
      : props.deliveryMethod === ""
      ? colors.blackColor
      : colors.primaryColor};
`;
const DelPrice = styled.span`
  text-align: left;
  padding: 1px;
  font-size: 1.2em;
  font-weight: ${(props) => (props.deliveryMethod === "Delivery" ? 600 : 500)};
  color: ${(props) =>
    props.deliveryMethod === "Delivery"
      ? colors.blackColor
      : props.deliveryMethod === ""
      ? colors.blackColor
      : colors.primaryColor};
`;
const OldPriceListView = styled.h4`
  text-align: left;
  padding: 5px;
  font-size: 0.8em;
  font-weight: 500;
  color: #636363;
  text-decoration: line-through 2px #979797;
`;
const CartActions = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  padding: 10px;
  height: 2.5rem;
`;

const QtyInput = styled.input`
  border: 1px solid ${colors.darkcolor} ;
  font-weight: 600;
  border-radius: 2px;
  margin-right: 5px;
  font-size: 0.9rem;

  color: ${colors.blackColor};
  text-align: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  @media only screen and (min-width: 768px) {
    padding-left: 5px;
    margin-left: 1vw;
    font-size: 1.1rem;
  }
`;

const CartInfo = styled.div`
  text-align: center;
  align-items: center;
  background-color: ${(props) =>
    props.qtyValue === 0 ? "transparent" : colors.lightcolor};
  color: white;
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 10px;
`;
const DeliveryMethodDiv = styled.div`
  align-items: center;
  width: 20rem;
  & div:hover {
    background-color: ${colors.primaryColor};
  }
`;
const WaitDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & p {
    margin-top: 0.8rem;
  }
  & h3 {
    text-align: center;
    margin-top: 0.8rem;
    color: ${colors.primaryColor};
  }
`;
const DeliveryMethodButton = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  align-items: center;
  text-align: center;
  color: ${colors.blackColor};
  margin: 1rem;
  padding: 5px;
  margin-top: 5px;
  background-color: ${colors.lightcolor};
`;
//001 ++ Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
const FavoriteInfo = styled.div`
 color: ${(props) => (props.offerEndDate ===''  ? colors.primaryColor :"#fbfbfb" )};
`;
//001 -- Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.

//002 ++ 'Offer Ends Bilgisi Gösterme.'
const SaleInfo = styled.div`
  font-weight: 700;
  font-size: 0.7rem;
  position: absolute;
  margin: 0.1rem;
  z-index: 4;
 
`;
const SaleImage = styled.img`
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  object-fit: contain;
`;
const ProductInfo = styled.div`
  display:flex ;
  flex:1 ;
  flex-direction:row ;
  padding:0.3rem ;
  height: 1.8rem;
  justify-content:space-between ;
  align-items:center ;
  background-color: ${(props) => (props.offerEndDate ===''  ? "#fbfbfb": colors.errorcolor)};
  font-weight: 700;
  font-size: 0.8rem;
  color:${(props) => (props.offerEndDate !==''  ? "#fbfbfb": colors.errorcolor)} ;
`;
const NewLabel = styled.div`
  font-weight: 800;
  font-size: 0.9rem;
`;
//002 -- 'Offer Ends Bilgisi Gösterme.'
//#endregion

function CardContent(props) {
  //#region VARIABLES
  const [qtyValue, setqtyValue] = useState(0);
  const [accordionData, setAccordionData] = useState([]);
  const [productCategory, setProductCategory] = useState({});
  const [productSubCategory, setProductSubCategory] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [disableValue, setDisabled] = useState(false);
  const inputRef = useRef(null);

  const [delMethodQty, setDelMethodQty] = useState(0);
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [deliveryError, setDeliveryError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [unit, setUnit] = useState("");
  const [units, setUnits] = useState([]);
  const [collectionPrice, setCollectionPrice] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [defaultImage, setDefaultImage] = useState(
    "https://www.expofoodsmidlands.co.uk/catalog/Products/noproduct.jpg"
  );

  const [open, setOpen] = useState(false);
  //001 ++ Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
  const [favorite,setFavorite] = useState(props.product.favorite)
  //001 -- Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
  //002 ++ 'Offer Ends Bilgisi Gösterme.'
  const [offerEndDate,setOfferEndDate]=useState('')
  //002 -- 'Offer Ends Bilgisi Gösterme.'
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    disableValue && setDisabled(false);
  };
  let addProductMethod = "";
  //#endregion

  useEffect(() => {
    initialUnit(unit,props.product,setUnits,setUnit,initialPrice,setQunatityFunc,props.cart);
    if (props.categories.length > 0) {
      let l_accordionData = [];
      l_accordionData.push({
        parentid: 0,
        title: "Other Informations",
        content: [
          `Description : ${props.product.description}`,
          `Item Category : ${props.product.itemcategory} `,
          `Pack Size : ${props.product.packsize} `,
          `Unit Size : ${props.product.unitsize} `,
          `Sales Unit : ${props.product.salesunit} `,
        ],
      });
      setAccordionData(l_accordionData);
      if (props.product.itemcategory !== "") {
        let cat = props.categories.find(
          (x) => x.code === props.product.itemcategory
        );
        setProductCategory(cat);
      }
      if (props.product.productgroup !== "") {
        let subCat = props.categories.find(
          (x) => x.code === props.product.productgroup
        );
        setProductSubCategory(subCat);
      }
    }
    setLoading(false);
  }, [props]);
  const onButtonClickHandler = (quantity) => {
    if(checkBlockedUnit(props.product,unit)){
      setDeliveryError(true)
      setAlertMessage("blocked for selected unit!");
    }else{
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        addProductMethod = "onClick";
        if (deliveryMethod === "") {
          setDelMethodQty(quantity);
          handleOpen();
        } else {
          addProductForButtonClick(quantity, deliveryMethod);
        }
      } else
        navigate("/route=account/login", {
          state: { href: window.location.href },
        });
    }
    
  };
  const addProductForButtonClick = (quantity, method) => {
    if (quantity === -1) {
      if (qtyValue > 1) {
        props.actions.addCartApi({
          product: props.product,
          quantity: -1,
          deliveryMethod: method,
          selectedUnit: unit,
          price: method === "Collection" ? collectionPrice : deliveryPrice,
        });
      } else if (qtyValue === 1) {
        props.actions.addCartApi({
          product: props.product,
          quantity: 0,
          deliveryMethod: method,
          selectedUnit: unit,
          price: method === "Collection" ? collectionPrice : deliveryPrice,
        });
      }
    } else {
      props.actions.addCartApi({
        product: props.product,
        quantity: 1,
        deliveryMethod: method,
        selectedUnit: unit,
        price: method === "Collection" ? collectionPrice : deliveryPrice,
      });
    }
  };
  const onChangeHandler = async (event) => {
    if (checkBlockedUnit(props.product, unit)) {
      setDeliveryError(true);
      setAlertMessage("blocked for selected unit!");
    } else {
      let name = event.target.name;
      let value = event.target.value;
      switch (name) {
        case "qtyinput":
          const access_token = localStorage.getItem("access_token");
          if (access_token) {
            addProductMethod = "onChangeHandler";
            if (value !== "") {
              let newQty = parseInt(value);
              let cartItem = props.cart.find(
                (c) => c.itemno === props.product.itemno
              );
              if (cartItem) {
                if (newQty === 0 || newQty < 0) {
                  setDisabled(true);
                  await props.actions.removeFromCart(cartItem.id);
                  setDisabled(false);
                  inputRef.current.focus();
                } else {
                  setDisabled(true);
                  await props.actions.updateCartItem({
                    cartItem: cartItem,
                    quantity: parseInt(newQty),
                  });
                  setDisabled(false);
                  inputRef.current.focus();
                }
              } else {
                if (newQty > 0) {
                  if (deliveryMethod === "") {
                    setDelMethodQty(parseInt(newQty));
                    handleOpen();
                  } else {
                    addProductForOnChangeHandler(
                      parseInt(newQty),
                      deliveryMethod
                    );
                  }
                } else {
                  setqtyValue(0);
                  setDisabled(false);
                  inputRef.current.focus();
                }
              }
            } else {
              setqtyValue("");
            }
          } else
            navigate("/route=account/login", {
              state: { href: window.location.href },
            });

          break;
        default:
        // code block
      }
    }
  };
  const addProductForOnChangeHandler = async (quantity, deliveryMethod) => {
    setDisabled(true);
    await props.actions.addCartApi({
      product: props.product,
      quantity,
      deliveryMethod,
      selectedUnit: unit,
      price: deliveryMethod === "Collection" ? collectionPrice : deliveryPrice,
    });
    setDisabled(false);
    inputRef.current.focus();
  };
  const changeDeliveryMethod = (method) => {
    if (!checkDeliveryDate(method,props.currentUser)) {
      setDeliveryError(true);
      setAlertMessage("Your delivery days is unknown !");
    } else {
      addProductMethod === "onClick"
        ? addProductForButtonClick(delMethodQty, method)
        : addProductForOnChangeHandler(delMethodQty, method);
    }
    handleClose();
  };
  const replaceImage = (error) => {
    //replacement of broken Image
    error.target.src = defaultImage;
  };
  const initialPrice = async (unit, l_units) => {
    if (props.currentUser.id) {
      let g_specialprice = 0;
      let g_specialpriceEndDate = '';
      let l_deliveryPrice = 0;
      let prices = props.product.prices.split(" ");
      let specialprices = props.product.specialprices.split(" ");
      props.product.specialprices !== "" && await Promise.all(
        JSON.parse(props.product.specialprices.toString()).map(async (specialprice) => {
          if (specialprice.unit === unit){
            g_specialprice = parseFloat(specialprice.unitprice);
            g_specialpriceEndDate = specialprice.endingdate
          } 
        })
      );
      await Promise.all(
        JSON.parse(props.product.prices.toString()).map((price) => {
          if (unit == price.unit) {
            l_deliveryPrice=price.unitprice
          }
        })
      );
      let baseUnitQtyPer = l_units.find(
        (u) => u.code === props.product.baseunit
      ).qtyPerUnit;
      let selectUnitQtyPer = l_units.find((u) => u.code === unit).qtyPerUnit;
      let unitPrice =
        (props.product.unitprice / baseUnitQtyPer) * selectUnitQtyPer;
      let colPrice =
        (props.product.collectionprice / baseUnitQtyPer) * selectUnitQtyPer;
      if (l_deliveryPrice === 0) l_deliveryPrice = unitPrice;
      setItemPrice(unitPrice);
      if(g_specialprice!==0 && g_specialprice < colPrice ) 
      {
        setCollectionPrice(g_specialprice)
        setOfferEndDate(g_specialpriceEndDate)
      }  else{
        setCollectionPrice(colPrice);
        setOfferEndDate('')
      } 
      if(g_specialprice!==0 && g_specialprice < l_deliveryPrice ) {
        setDeliveryPrice(g_specialprice)
        setOfferEndDate(g_specialpriceEndDate)
      } else {
        setDeliveryPrice(l_deliveryPrice);
        setOfferEndDate('')
      }
    }
  };
  const setQunatityFunc = (unit) => {
    let cartItem = props.cart.find(
      (c) => c.itemno === props.product.itemno && c.itemunit === unit
    );
    props.cart.length > 0
      ? setDeliveryMethod(props.cart[0].deliverymethod)
      : setDeliveryMethod("");
    if (cartItem) setqtyValue(cartItem.quantity);
    else setqtyValue(0);
  };
  const handleChangeUnit = (event) => {
    initialPrice(event.target.value, units);
    setUnit(event.target.value);
    setQunatityFunc(event.target.value);
  };
  //001 ++ Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
  const controlFavoriteProduct = async (value)=>{
    const access_token = localStorage.getItem("access_token");
      if (access_token) {
        const body = {
          itemno:props.product.itemno,
          favorite: value
        }
        await  productActions.controlFavoriteProductApi(body,setFavorite)
        if(!value)
          (props.refreshFavoriteList) && await props.refreshFavoriteList()
      } else{
        navigate("/route=account/login", {
          state: { href: window.location.href },
        });
      }
  }
  //001 -- Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
  //#endregion

  return (
    <Container>
      {!loading ? (
        <Container>
          <OrcaModal isOpen={open} onClose={handleClose}>
            <DeliveryMethodDiv>
              <DeliveryMethodButton
                onClick={() => changeDeliveryMethod("Delivery")}
              >
                Delivery{" "}
              </DeliveryMethodButton>
              <DeliveryMethodButton
                onClick={() => changeDeliveryMethod("Collection")}
              >
                Collection{" "}
              </DeliveryMethodButton>
            </DeliveryMethodDiv>
          </OrcaModal>
          <OrcaModal isOpen={disableValue} onClose={() => setDisabled(false)}>
            <WaitDiv>
              <CircularProgress size={40} thickness={5} />
              <h3>PROCESS CONTINUING</h3>
              <p>Please wait ...</p>
            </WaitDiv>
          </OrcaModal>
          <Row>
            <BreadCrumbText
              onClick={() => {
                navigate("/");
              }}
            >
              HOME
            </BreadCrumbText>
            {productCategory && productCategory.code && (
              <BreadCrumbText
                onClick={() => {
                  navigate("/route=search?categoryid=" + productCategory.code);
                }}
              >
                {productCategory.description}
              </BreadCrumbText>
            )}
            {productSubCategory && productSubCategory.code && (
              <BreadCrumbText
                onClick={() => {
                  navigate(
                    `/route=search?categoryid=${productCategory.code}&productid=${productSubCategory.code}`
                  );
                }}
              >
                {" "}
                {productSubCategory.description}{" "}
              </BreadCrumbText>
            )}
            <BreadCrumbText style={{ color: "black" }}>
              {props.product.description}
            </BreadCrumbText>
          </Row>
          <Row2>
          
          <ProductInfo offerEndDate={offerEndDate}>
              <NewLabel >{props.product.newitem && 'NEW'  } </NewLabel>
              <div>{offerEndDate!=='' && 'OFFER ENDS '+offerEndDate}</div> 
              <FavoriteInfo offerEndDate={offerEndDate} onClick={()=>controlFavoriteProduct(!favorite)}>
                { favorite ? <SelectedFavoriteIcon sx={{width:'1.7rem',height:'1.7rem',color:`${colors.secondryColor}`}}/> : <FavoriteIcon sx={{width:'1.7rem',height:'1.7rem'}}/> } 
              </FavoriteInfo>
            </ProductInfo>
           
          </Row2>
          <Row3>
          <SaleInfo>
              {(deliveryMethod === "Collection" &&itemPrice - collectionPrice > 0.05) ||(deliveryMethod === "Delivery" && itemPrice - deliveryPrice > 0.05)
                ? <SaleImage src={`./images/sale.png`} />
              : ""}
            </SaleInfo>
            
            
            <Image
              src={`/${props.product.image}`}
              alt={`${props.product.description}`}
              onError={replaceImage}
            />
            
            <DDiv>
              <Details>
                
                <Title>{props.product.description}</Title>
                <Text>Product No : {props.product.itemno}</Text>
                <Text>Unit : {props.product.salesunit}</Text>
                <Text>Pack Size : {props.product.packsize}</Text>
                <Text>Unit Size : {props.product.unitsize}</Text>
                <Text>VAT %: {props.product.vat}</Text>
                <Text>
                  Stock :{" "}
                  {props.product.stockqty &&
                    (props.product.stockqty < 0 ? 0 : props.product.stockqty)}
                </Text>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: '15rem' }}
                  size="small"
                >
                  <InputLabel id="demo-select-small">Unit</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    sx={{ color:'#AAA',border: "1px solid #E6E6E6", }}
                    value={unit}
                    label="Unit"
                    onChange={handleChangeUnit}
                  >
                    {units.map((u) => {
                      return (
                        <MenuItem value={u.code} key={u.code}>
                          {u.display}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {props.settings.pricevisible && props.currentUser.id && (
                  <PriceDiv>
                    <PriceDiv2>
                      <ColPrice deliveryMethod={deliveryMethod}>
                        Collection : £ {collectionPrice.toFixed(2)} - Incl. VAT
                        : £{" "}
                        {(
                          collectionPrice +
                          (collectionPrice * props.product.vat) / 100
                        )
                          .toFixed(2)
                          .toString()}
                      </ColPrice>
                      {itemPrice - collectionPrice > 0.05 && (
                        <OldPriceListView>
                          £{" "}
                          {(itemPrice + (itemPrice * props.product.vat) / 100)
                            .toFixed(2)
                            .toString()}
                        </OldPriceListView>
                      )}
                    </PriceDiv2>
                    <PriceDiv2>
                      <DelPrice deliveryMethod={deliveryMethod}>
                        Delivery: £ {deliveryPrice.toFixed(2)} - Incl. VAT : £{" "}
                        {(
                          deliveryPrice +
                          (deliveryPrice * props.product.vat) / 100
                        )
                          .toFixed(2)
                          .toString()}
                      </DelPrice>
                      {itemPrice - deliveryPrice > 0.05 && (
                        <OldPriceListView>
                          £{" "}
                          {(itemPrice + (itemPrice * props.product.vat) / 100)
                            .toFixed(2)
                            .toString()}
                        </OldPriceListView>
                      )}
                    </PriceDiv2>
                  </PriceDiv>
                )}
              </Details>

              <CartActions>
                <QtyInput
                  name="qtyinput"
                  onChange={onChangeHandler}
                  type="number"
                  value={qtyValue}
                  disabled={disableValue}
                  ref={inputRef}
                />
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button
                    style={{ borderRadius: "1px",border:`1.5px solid ${colors.darkcolor}`}}
                    onClick={() => {
                      onButtonClickHandler(-1);
                    }}
                  >
                    <Remove
                      style={{
                        color:`${colors.blackColor}`,
                        flex: "1",
                        height: "100%",
                      }}
                    />
                  </Button>
                  <Button
                    style={{ borderRadius: "1px",border:`1.5px solid ${colors.darkcolor}` }}
                    onClick={() => {
                      onButtonClickHandler(1);
                    }}
                  >
                    <Add
                      style={{
                        color:`${colors.blackColor}`,
                        flex: "1",
                        height: "100%",
                      }}
                    />
                  </Button>
                </ButtonGroup>
              </CartActions>
            </DDiv>
          </Row3>
          {accordionData.map((item) => {
            return <CartAccordionMenu key={item.title} item={item} />;
          })}
        </Container>
      ) : (
        <Container></Container>
      )}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={deliveryError}
          autoHideDuration={1500}
          onClose={() => setDeliveryError(false)}
        >
          <Alert
            onClose={() => setDeliveryError(false)}
            severity="error"
            sx={{ width: "100%", padding: "14px 12px" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </Stack>
    </Container>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      addCartApi: bindActionCreators(cartActions.addCart, dispatch),
      updateCartItem: bindActionCreators(cartActions.updateCartItem, dispatch),
      removeFromCart: bindActionCreators(cartActions.removeFromCart, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return {
    cart: state.cartActionReducer,
    categories: state.categoryListReducer,
    settings: state.settingReducer,
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CardContent);
