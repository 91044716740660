export const colors={
    primaryColor:'#dddddd',
    blackColor:'#000000',
    secondryColor :'#cf1e2d',
    lightcolor:'#a1a1a1',
    darkcolor:'#9b9b9b',
    salecolor:'#3f793e',
    weboffercolor:'#CD1B1B',
    errorcolor:'#CD1B1B'
}
export const STRIPE_KEY="pk_test_51L3ElqA2ViOJ9APC3pEByGMiNNF0Ir9FqKERDjIJyz9hw4nv3wOLoOX2w4MqOnDPBW4YdpXnF1ei3kf14nHB76MS00vJ9XmKRe"

export const API_URL='https://www.tushcin.com:3003/api'




//1e54aa -- expo mid color
//008080 -- orca color