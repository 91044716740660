
import styled from "styled-components";
import useForm from "../../utilities/hooks/useForm";
import {validateInfo} from '../../utilities/helpers';

//#region Styles

const Container = styled.div`

`;
const ImageDiv = styled.div`
  background-color:#fff ;
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  
`;
const DivContent = styled.div`
  width: 50%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const ContactUsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem;
 
  font-family: "Raleway", sans-serif;
  background-color: #fff ;
  & p {
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    line-height: 26px;
    color: #818181;
    font-weight: 400;
  }
  & h2 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
  & h4 {
    color: #ffffff;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const ContactMapDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem;
 
  font-family: "Raleway", sans-serif;
  background-color: #fff ;
  & p {
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    line-height: 26px;
    color: #818181;
    font-weight: 400;
  }
  & h2 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
  & h4 {
    color: #ffffff;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  border-color: lightgray;
  border-style: solid;
  &:focus {
    outline: none !important;
    border-color: white;
  }
`;
const MultiInput = styled.textarea`
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  height: 15vw;
  border-color: lightgray;
  border-style: solid;
  text-align: start;
`;
const Button = styled.input`
  color: #aa182c;
  font-size: 15px;
  font-weight: 600;
  border: none;
  padding: 15px 20px;
  background-color: #f7f7f7;
  opacity: 80%;
  margin-bottom: 10px;
`;

//#endregion
function HomePageInformationContent(props) {
  const { handleChange, handleSubmit, values, errors } = useForm(
    props.sendMail,
    validateInfo
  );
  return (
    <Container>
      <ContactUsDiv>
        <DivContent>
          <h2>WELCOME TO DRAGON LONDON LTD</h2><p>&nbsp;</p>
          <p>Dragon London Ltd is your trusted wholesale and distribution partner, providing a wide range of high-quality products to independent retailers across the UK. With over two decades of experience in the industry, we understand the needs of your business and are committed to delivering exceptional service and reliable supply chains that you can count on.</p>
          <p>&nbsp;</p>
          <p>While we specialise in premium Polish brands such as Sokolow meats and Polish dairy, our product range continues to expand to include soft drinks, confectionery, nuts, sweets, and toys. Our diverse selection is designed to meet the changing demands of your customers, helping your business grow and thrive in a competitive market.</p>
          <p>&nbsp;</p>
          <p>At Dragon London Ltd, we work closely with you to optimise your store layout, ensuring products are displayed in a way that boosts visibility and drives sales. We’re here to offer expert advice on product placement and stock management, so you can focus on what matters most: growing your business.</p>
        </DivContent>
        <DivContent>
            <ImageDiv>
                <Image
                    alt=""
                    loading="lazy"
                    title="homepagebanner"
                    src={`./catalog/Banners/dragonlondonltdcontenthomepage1.jpg`} />
            </ImageDiv>
        </DivContent>
      </ContactUsDiv>
      <ContactMapDiv>
        <DivContent>
            <ImageDiv>
                <Image
                    alt=""
                    loading="lazy"
                    title="homepagebanner"
                    src={`./catalog/Banners/dragonlondonltdcontenthomepage2.jpg`} />
            </ImageDiv>
        </DivContent>
        <DivContent>
          <h2> LEADERS IN WHOLESALE AND DISTRIBUTION ON THE BRITISH MARKET </h2><p>&nbsp;</p>
          <p>At Dragon London Ltd, we take pride in being leaders in the wholesale and distribution sector, offering not just products but also value-added services that enhance your business. Our strategically located distribution centres ensure fast, reliable deliveries across the UK, keeping your store well-stocked at all times.</p><p>&nbsp;</p>
          <p>Our team uses the latest technology to streamline the ordering process and provide a personalised service that goes beyond just logistics. We visit your store, assess your needs, and tailor our service to fit your business requirements. Whether you order online or directly with us, our efficient delivery system ensures you get the products you need, when you need them.</p><p>&nbsp;</p>
          <p>Dragon London Ltd is more than just a supplier—we’re your partner in success, offering innovative solutions to improve your store’s efficiency and profitability.</p><p>&nbsp;</p>
        </DivContent>
      </ContactMapDiv>
    </Container>
  );
}

export default HomePageInformationContent;
