import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SolutionsContent from "../components/PagesComponents/SolutionsContent";
const Container = styled.div``;
const About = () => {
  useEffect(()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  },[])
  return (
    <Container>
      <Header />
      <SolutionsContent />
      <Footer />
    </Container>
  );
};

export default About;
