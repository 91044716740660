
import styled from "styled-components";

//#region Styles

const Container = styled.div`
`;
const ImageDiv = styled.div`
  background-color:#dbdbdb ;
`;
const InformationDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem;
 
  font-family: "Raleway", sans-serif;
  background-color: #fff ;
  & p {
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #818181;
    font-weight: 400;
  }
  & h1 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
    
  & h2 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
  & h4 {
    color: #ffffff;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const DivContent = styled.div`
  width: 50%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;
//#endregion
function AboutContent() {
  return (
    <Container>
      <ImageDiv>
        <Image
              alt=""
              loading="lazy"
              title="homepagebanner"
              src={`./catalog/Banners/dragonlondonltdheader2.jpg`} />
      </ImageDiv>
      <InformationDiv>
        <DivContent>
            <ImageDiv>
                <Image
                    alt=""
                    loading="lazy"
                    title="homepagebanner"
                    src={`./catalog/Banners/dragonlondonltdcontentAbout1.jpg`} />
            </ImageDiv>
        </DivContent>
        <DivContent>
          <h1> WE ARE WORLD FOOD SPECIALISTS </h1><p>&nbsp;</p>
          <p>At Dragon London Ltd, we are dedicated to making your business more profitable and efficient. Our mission is to deliver the best products on the market, ensuring your store is always stocked with high-demand items that sell quickly. With our expert team, we’ll help you optimise your store layout, maximising product visibility to increase sales and enhance the customer experience.</p><p>&nbsp;</p>
          <p>You can rely on our fast, dependable deliveries—our fleet of vans is fully equipped to bring fresh, top-quality products directly to your shop, on time, every time. Our sales team, utilising the latest technology, visits your store to take orders and ensure the products are perfectly positioned on your shelves, ready to be sold. With Dragon London Ltd, you’ll experience faster stock turnover, happier customers, and greater operational efficiency.</p><p>&nbsp;</p>
        </DivContent>
      </InformationDiv>
      <InformationDiv>
        
        <DivContent>
          <h1> HELPING YOUR BUSINESS TO THRIVE </h1><p>&nbsp;</p>
          <p>In the food industry, quality is key—and we provide only the best. At Dragon London Ltd, we offer premium branded products that are rigorously inspected to meet the highest standards. From meats and dairy to confectionery and beverages, every item we supply comes from trusted sources, giving your customers confidence in every purchase.</p><p>&nbsp;</p>
          <p>Our strong partnerships with top manufacturers, including leading Polish brands, allow us to offer exclusive, high-quality products at competitive prices. This means your store will always have a unique, appealing range on display—helping you stand out from the competition and keep customers returning for more.</p><p>&nbsp;</p>
        </DivContent>
        <DivContent>
            <ImageDiv>
                <Image
                    alt=""
                    loading="lazy"
                    title="homepagebanner"
                    src={`./catalog/Banners/dragonlondonltdcontentAbout2.jpg`} />
            </ImageDiv>
        </DivContent>
      </InformationDiv>
    </Container>
  );
}

export default AboutContent;
