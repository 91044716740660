import { useState,useEffect } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MenuBar from "../components/MenuComponents/MenuBar";
import ShoppingCartContent from "../components/PagesComponents/ShoppingCartContent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingsActions from "../redux/actions/settingsActions";
import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
const Container = styled.div`
  display:flex ;
  flex:1 ;
  flex-direction:column ;
  min-height: ${(props) => props.height}px;
  min-width: ${(props) =>  props.width >1780 ? "1780px" : `${props.width}px`};
  justify-content:space-between ;
`;
function ShoppingCart(props) {
  const { height,width } = useWindowWidthAndHeight();
  useEffect(()=>{
    props.actions.getSettings()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },[])
  return (
    <Container height={height} width={width}>
      <div>
        <Header />
      </div>
      <ShoppingCartContent/>
      <Footer />
    </Container>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getSettings:bindActionCreators(settingsActions.getSettings,dispatch)
    },
  };
}
export default connect(null,mapDispatchToProps)(ShoppingCart) ;
