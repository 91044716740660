
import styled from "styled-components";
import useForm from "../../utilities/hooks/useForm";
import {validateInfo} from '../../utilities/helpers';

//#region Styles

const Container = styled.div`

`;
const ImageDiv = styled.div`
  background-color:#dbdbdb ;
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  
`;
const DivContent = styled.div`
  width: 50%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const ContactUsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem;
 
  font-family: "Raleway", sans-serif;
  background-color: #dbdbdb ;
  & p {
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    line-height: 26px;
    color: #818181;
    font-weight: 400;
  }
  & h2 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
  & h4 {
    color: #ffffff;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const ContactMapDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem;
 
  font-family: "Raleway", sans-serif;
  background-color: #fff ;
  & p {
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    line-height: 26px;
    color: #818181;
    font-weight: 400;
  }
  & h2 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
  & h4 {
    color: #ffffff;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  border-color: lightgray;
  border-style: solid;
  &:focus {
    outline: none !important;
    border-color: white;
  }
`;
const MultiInput = styled.textarea`
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  height: 15vw;
  border-color: lightgray;
  border-style: solid;
  text-align: start;
`;
const Button = styled.input`
  color: #aa182c;
  font-size: 15px;
  font-weight: 600;
  border: none;
  padding: 15px 20px;
  background-color: #f7f7f7;
  opacity: 80%;
  margin-bottom: 10px;
`;

//#endregion
function ContactContent(props) {
  const { handleChange, handleSubmit, values, errors } = useForm(
    props.sendMail,
    validateInfo
  );
  return (
    <Container>
      <ImageDiv>
        <Image
              alt=""
              loading="lazy"
              title="homepagebanner"
              src={`./catalog/Banners/dragonlondonltd-header-contact-scaled.jpg`} />
      </ImageDiv>
      <ContactUsDiv>
        <DivContent>
          <h2> LET’S HAVE A CHAT</h2>
          <p>At Dragon London, we make sure consumers go first.</p>
          <p>&nbsp;</p>
          <p>We are a premium wholesale food supplier of high-quality ethnic foods.</p>
          <p>&nbsp;</p>
          <p>Our staff will provide a reliable service to our clients, ensuring order-taking, deliveries and account management is dealt with efficiently and hassle-free.</p>
          <p>&nbsp;</p>
          <p>If you have any questions, please get in touch using the form below.</p>
        </DivContent>
        <DivContent>
          <Form onSubmit={handleSubmit}>
            <Input
              name="email"
              onChange={handleChange}
              type="text"
              placeholder="email"
              value={values.email}
            />
            {errors.email && <p style={{color:'red'}}>{errors.email}</p>}
            <Input
              name="name"
              onChange={handleChange}
              type="text"
              placeholder="name"
              value={values.name}
            />
            {errors.name && <p style={{color:'red'}}>{errors.name}</p>}
            <MultiInput
              name="message"
              onChange={handleChange}
              type="text"
              placeholder="your message (optional)"
              value={values.message}
            />
            {errors.message && <p style={{color:'red'}}>{errors.message}</p>}
            <Button type="submit" value="SUBMIT"></Button>
          </Form>
        </DivContent>
      </ContactUsDiv>
      <ContactMapDiv>
        <DivContent>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9889.595958226768!2d-0.025805!3d51.7074426!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb1ae817210639b35!2sDragon%20London%20Limited!5e0!3m2!1sen!2suk!4v1635257877962!5m2!1sen!2suk"
            width="100%"
            height="400"
            style={{borderWidth:1,borderStyle:"solid",borderColor:"#ddd",marginTop:5}}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </DivContent>
        <DivContent>
          <h2> GET IN TOUCH NOW </h2>
          <p>Unit 4, Armitage Business Centre,</p>
          <p>Cheshunt, Waltham Cross</p>
          <p>EN8 9FN</p>
          <p>Phone:01992 630420</p>
        </DivContent>
      </ContactMapDiv>
    </Container>
  );
}

export default ContactContent;
