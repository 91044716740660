import { Divider } from "@material-ui/core";
import { AddLocation, Call, Email,Facebook,Twitter } from "@material-ui/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { colors } from "../res/values/values";
const Container = styled.div`
  color: ${colors.primaryColor};
  background-color:${colors.blackColor};
  width: 100%;
`;
const ImageDiv = styled.div`
  display: flex;
  flex-direction:column ;
  justify-content:center ;
  align-items:center ;
  @media only screen and (min-width: 600px) {
    height:100% ;
  }
  
`;
const Image = styled.img`
  object-fit: contain;
  height:5rem ;
`;
const Top = styled.div`
  padding: 10px;
  display: flex;
  margin: 0 5px;
  justify-content: space-around;
  flex-direction:column ;
  @media only screen and (min-width: 600px) {
    flex-direction: row;
    margin: 0 40px;
  }

`;
const ColumnList = styled.ul`
  list-style-type: none;
  margin-left: 10px;
  margin-right:10px ;
  padding: 0;
  cursor: pointer;

`;
const ColumnListTitle = styled.li`
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 1px;
`;
const ColumnListItem = styled.li`
  margin-bottom: 5px;
  letter-spacing: 1px;
  display: flex;

`;
const Bottom = styled.div`
    padding:5px ;
    background-color: ${colors.blackColor};
    text-align:center ;
    margin-top:1px ;
  & p {
    margin: 8px 0;
    color: #ffffff;
    font-size: 0.8rem;
    & a {
      color: #ffffff;
      font-size: 0.8rem;
    }
  }
`;
function Footer() {
  const navigate = useNavigate();
  return (
    <Container>
      <Top>
        <ColumnList>
        <ImageDiv>
          <Image
                alt=""
                loading="lazy"
                title="homepagebanner"
                src={`./catalog/Banners/ldragonlondonltdLogo.png`} />
        </ImageDiv>
        
          
        </ColumnList>
        <ColumnList>
          <ColumnListTitle>Contact Us</ColumnListTitle>
          <ColumnListItem>
            <AddLocation style={{ marginRight: "5px" }} />
            Unit 4, Armitage Business Centre ,Cheshunt, Waltham Cross EN8 9FN.
          </ColumnListItem>
          <ColumnListItem>
            <Call style={{ marginRight: "5px" }} />
            Phone:01992 630420
          </ColumnListItem>
          <ColumnListItem>
            <Email style={{ marginRight: "5px" }} />
            info@dragonlondonltd.com
          </ColumnListItem>
        </ColumnList>
        <ColumnList>
          <ColumnListTitle>Follow Us</ColumnListTitle>
          <ColumnListItem>
            <Facebook style={{ marginRight: "5px" }} />
            Facebook
          </ColumnListItem>
          <ColumnListItem>
            <Twitter style={{ marginRight: "5px" }} />
            Twitter
          </ColumnListItem>
          
        </ColumnList>
        <ColumnList>
          <ColumnListTitle >About Us</ColumnListTitle>
          <ColumnListItem onClick={(_) => navigate("/route=contact")}>
            Contact
          </ColumnListItem>
          <ColumnListItem onClick={(_) => navigate("/route=about")}>
            About
          </ColumnListItem>
          <ColumnListItem onClick={(_) => navigate("/route=solutions")}>
            Solutions
          </ColumnListItem>
        </ColumnList>
      </Top>
      <Divider style={{ margin: "5px 0" }} />

      <Bottom>
      <p>Powered By <a href="http://www.orcabs.com">Orca Business Solutions Ltd.  -  </a> Dragon London Limited© 2023</p>
      </Bottom>
      <Divider style={{ margin: "5px 0" }} />
    </Container>
  );
}

export default Footer;
