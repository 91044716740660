import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../redux/actions/cartActions";
import { useNavigate } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import { colors } from "../../res/values/values";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { forwardRef ,useState} from "react";

import { checkDeliveryDate } from "../../utilities/helpers";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
//#region Styles
const CartDiv = styled.div`
  margin: 1px;
  height: 100%;
  min-height: 48vh;
  max-height: 48vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${colors.blackColor};
  font-size: 0.8rem;
  font-weight: 500;
  padding:0.5rem ;
`;
const ScrollDiv = styled.div`
  min-height: 35vh;
  max-height: 35vh;
  overflow-y: scroll;
  white-space: nowrap;
  margin-top:0.5rem ;
`;
const CartTable = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
`;
const CartBody = styled.tbody`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
`;
const CartRow = styled.tr`
  height: 10px;
  letter-spacing: 1px;
  border-bottom: 1px solid ${colors.darkcolor};;
`;
const CartHeaderCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid ${colors.blackColor};
  text-align: left;
`;
const CartRowCell = styled.td`
  padding: 10px;
  text-align: left;
`;
const CartFooter = styled.div`
  padding: 5px;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CartTotal = styled.div``;
const CartButtons = styled.div`
  display: flex;
`;
const CartButton = styled.div`
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 1px;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  background-color: ${colors.blackColor};
  border-color: #dddddd #dddddd #b3b3b3 #b7b7b7;
  color: ${colors.primaryColor};
  border-radius: 2px;
  margin: 2px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.secondryColor};
  }
`;
const CartRowButtonCell = styled.td`
  padding: 5px;
  border: 1px solid ${colors.secondryColor};
  text-align: left;
  border-width: 0px;
  &:hover {
    background-color: #ddd;
    cursor: pointer;
  }
`;
const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #6e6e6e;
  font-size: 0.7rem;
  font-weight: 500;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
`;
const MobileTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const MobileDescription = styled.div`
  color: ${colors.blackColor};
  margin-bottom: 0.5rem;
  margin-right:0.5rem ;
`;
const MobileInformation = styled.div`
  margin-left: 0.5rem;
`;
const MobileDelete = styled.div`
  margin-right:0.5rem ;
  & :hover{
    color:red;
  }
`;
//#endregion
function CartMenuContent(props) {
  const [deliveryError, setDeliveryError] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const { width } = useWindowWidthAndHeight();
  const navigate = useNavigate();
  const getTotalAmount = () => {
    let totalAmount = 0;
    props.cart.map((item) => (totalAmount += item.lineamount));
    return totalAmount.toFixed(2);
  };
  const handleChange = async (event) => {
    console.log(props.cart[0].deliverymethod);
    if (!checkDeliveryDate(event.target.value,props.currentUser)) {
      setDeliveryError(true)
      setAlertMessage('Your delivery days is unknown !')
    } else
      await props.actions.updateCartDeliveryMethod(event.target.value)
  };
  if (width < 768) {
    return (
      <CartDiv>
        <FormControl>
        <RadioGroup
          row
          aria-labelledby="radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={props.cart[0].deliverymethod}
          onChange={handleChange}
           sx={{marginLeft:"5px"}}
        >
          <FormControlLabel
            value="Delivery"
            control={<Radio sx={{
                
                color: colors.blackColor,
                '&.Mui-checked': {
                  color:colors.blackColor,
                },
                
              }} size="small" />}
            label="Delivery"
          />
          <FormControlLabel
            value="Collection"
            control={<Radio sx={{
                color: colors.blackColor,
                '&.Mui-checked': {
                  color: colors.secondryColor,
                },
              }} size="small"/>}
            label="Collection"
          />
        </RadioGroup>
        </FormControl>
        <ScrollDiv>
          {props.cart.map((line, index) => {
            return (
              <MobileContent key={index} >
                <MobileTop>
                  <MobileDescription>{line.description}</MobileDescription>
                  <MobileDelete onClick={()=>props.actions.removeFromCart(line.id)}><DeleteOutline></DeleteOutline></MobileDelete>
                </MobileTop>
                <MobileInformation>
                  @{line.itemunit} * £ {line.quantity.toFixed(2)} - £{" "}
                  {line.lineamount.toFixed(2)}
                </MobileInformation>
                
              </MobileContent>
            );
          })}
        </ScrollDiv>
        
        <CartFooter>
          <CartButtons>
            <CartButton onClick={(_) => navigate("/route=checkout/cart")}>
              View Cart
            </CartButton>
          </CartButtons>
          <CartTotal>Total : £ {getTotalAmount()}</CartTotal>
        </CartFooter>
        <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={deliveryError}
              autoHideDuration={2000}
              onClose={() => setDeliveryError(false)}
            >
              <Alert
                onClose={() => setDeliveryError(false)}
                severity="error"
                sx={{ width: "100%", padding: "18px 16px" }}
              >
               {alertMessage}
              </Alert>
            </Snackbar>
          </Stack>
      </CartDiv>
    );
  } else {
    return (
      <CartDiv>
        <FormControl>
        <RadioGroup
          row
          aria-labelledby="radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={props.cart[0].deliverymethod}
          onChange={handleChange}
          sx={{marginLeft:"5px"}}
        >
          <FormControlLabel
            value="Delivery"
            control={<Radio sx={{
                color: colors.blackColor,
                '&.Mui-checked': {
                  color:colors.blackColor,
                },
                
              }} size="small" />}
            label="Delivery"
          />
          <FormControlLabel
            value="Collection"
            control={<Radio sx={{
                color: colors.blackColor,
                '&.Mui-checked': {
                  color: colors.blackColor,
                },
              }} size="small"/>}
            label="Collection"
          />
        </RadioGroup>
        </FormControl>
        <ScrollDiv>
          <CartTable>
            <CartBody>
              <CartRow>
                <CartHeaderCell>Qty</CartHeaderCell>
                <CartHeaderCell>Item</CartHeaderCell>
                <CartHeaderCell>Unit</CartHeaderCell>
                <CartHeaderCell style={{ textAlign: "right" }}>
                  Amount
                </CartHeaderCell>
                <CartHeaderCell></CartHeaderCell>
              </CartRow>
            </CartBody>
            <CartBody>
              {props.cart.map((item, index) => {
                return (
                  <CartRow key={index}>
                    <CartRowCell>{item.quantity}</CartRowCell>
                    <CartRowCell>{item.description}</CartRowCell>
                    <CartRowCell>{item.itemunit}</CartRowCell>
                    <CartRowCell style={{ textAlign: "right" }}>
                      £ {item.lineamount.toFixed(2)}
                    </CartRowCell>
                    <CartRowButtonCell
                      onClick={() => {
                        props.actions.removeFromCart(item.id);
                      }}
                      style={{ textAlign: "center" }}
                    >
                      <DeleteOutline></DeleteOutline>
                    </CartRowButtonCell>
                  </CartRow>
                );
              })}
            </CartBody>
          </CartTable>
        </ScrollDiv>
        <CartFooter>
          <CartButtons>
            <CartButton onClick={(_) => navigate("/route=checkout/cart")}>
              View Cart
            </CartButton>
          </CartButtons>
          <CartTotal>Total : £ {getTotalAmount()}</CartTotal>
        </CartFooter>
        <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={deliveryError}
              autoHideDuration={2000}
              onClose={() => setDeliveryError(false)}
            >
              <Alert
                onClose={() => setDeliveryError(false)}
                severity="error"
                sx={{ width: "100%", padding: "18px 16px" }}
              >
               {alertMessage}
              </Alert>
            </Snackbar>
          </Stack>
      </CartDiv>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      removeFromCart: bindActionCreators(cartActions.removeFromCart, dispatch),
      updateCartDeliveryMethod: bindActionCreators(cartActions.updateCartDeliveryMethod, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUserReducer,
    cart: state.cartActionReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CartMenuContent);
