import React, { useState, useEffect, Fragment, useRef } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../redux/actions/productActions";
import { colors,API_URL } from "../res/values/values";
import { Search } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { speedDialClasses } from "@mui/material";
import { spacing } from "@mui/system";
//#region styles

const SearchInput = styled.input`
  width: 30vw;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 1.5px;
  padding: 0 1.5rem;
  outline: none;
  background-color: ${colors.primaryColor};
  border-style: solid;
  border-color: lightgray;
  border-width: 1px 0 1px 1px;
  @media only screen and (min-width: 600px) {
    font-size: 1rem;
  }
`;
const Suggestions = styled.ul`
  position: absolute;
  z-index: 1000;
  
  overflow-x: auto;
  padding: 1px 0;
  margin-top: 40px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border-radius: 0.25rem;
  border: 1px solid #999;
  list-style: none;
  width: 80vw;
  max-height: 70vw;
  @media only screen and (min-width: 600px) {
    width: 60vw;
    max-height: 45vw;
  }
`;
const SuggestionsLi = styled.li`
  padding: 5px;
  border-bottom: 1px solid #999;
  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
    font-weight: 700;
  }
`;
const SuggestionsActiveLi = styled.li`
  padding: 5px;
  background-color: #e7e7e7;
  border-bottom: 1px solid #999;
  cursor: pointer;
  font-weight: 700;
`;
const NoSuggestions = styled.div`
  margin-top: 35px;
  position: absolute;
  z-index: 1000;
  color: #999;
  padding: 0.5rem;
`;
const ClearButton = styled.button`
  background-color: ${colors.primaryColor};
  border: 0;
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
`;
const ClearButtonText = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.blackColor};
  margin: 0.5rem;
`;
const ClearButtonTextNon = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: transparent;
  margin: 0.5rem;
`;
const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-color: lightgray;
  border-width: 1px 1px 1px 1px;
  padding: 10px;
  color: ${colors.blackColor};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;
const Image = styled.img`
  height: 3rem;
  width: 3rem;
  object-fit: contain;
  margin:5px ;
  @media only screen and (min-width: 600px) {
    height: 4.5rem;
    width: 4.5rem;
  }
 
`;
const Description = styled.p`
  display: block;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 700;
  margin-top: 5px;
  @media only screen and (min-width: 600px) {
    font-size: 17px;
  }
 
`;
const Unit = styled.p`
  display: block;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
  margin-top: 5px;
  color: #4f4f4f;
  @media only screen and (min-width: 600px) {
    font-size: 17px;
  }
 
`;
const Price = styled.p`
  display: block;
  font-size: 13px;
  margin-left: 10px;
  margin-top: 5px;
  font-weight: 500;
  color: #797d7f;
  @media only screen and (min-width: 600px) {
    font-size: 16px;
  }
  
`;
const SuggestionHeader = styled.div`
  padding:10px ;
  font-size: 16px;
  font-weight:500 ;
  color: ${colors.blackColor};
  background-color: ${colors.primaryColor};
`;
const CartButtons = styled.div`
  display: flex;
  flex:1 ;
  justify-content:center ;
`;
const CartButton = styled.div`
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  background-color: ${colors.primaryColor};
  border-color: #dddddd #dddddd #b3b3b3 #b7b7b7;
  color: ${colors.blackColor};
  border-radius: 2px;
  padding: 8px;
  margin:5px ;
  cursor: pointer;
  &:hover{
    background-color: ${colors.blackColor};
    color: ${colors.primaryColor};
  }
`;
//#endregion
const Autocomplete = (props) => {
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  const listRef= useRef(null);
  const element= useRef(null);
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [defaultImage,setDefaultImage]=useState('https://www.expofoodsmidlands.co.uk/catalog/Products/noproduct.jpg')
  useEffect(() => {
    //props.actions.getAllProducts();
  }, []);

  const initialUnit = async (product) => {
    let units = JSON.parse(product.units.toString());
    let itemUnits = [];

    await Promise.all(units.map(async (unit,index)=>{
      let unitValue = {
        code: unit.unit,
        qtyPerUnit: unit.qtyperunit,
      };
      itemUnits.push(unitValue);
    }))
    return itemUnits
    //await initialPrice(product, itemUnits);
  };

  const initialPrice = async (product, l_units,specialprice) => {

    if (props.currentUser.id) {

      let l_collectionPrice=0
      let l_deliveryPrice=0
      
      await Promise.all(
        JSON.parse(product.prices.toString()).map((price) => {
          if (product.salesunit == price.unit) {
            l_deliveryPrice=price.unitprice
          }
        })
      );
      let baseUnitQtyPer = l_units.find((u) => u.code === product.baseunit).qtyPerUnit;
      let selectUnitQtyPer = l_units.find((u) => u.code === product.salesunit).qtyPerUnit;
      let colPrice =(product.collectionprice / baseUnitQtyPer) *selectUnitQtyPer;
      let unitPrice =(product.unitprice / baseUnitQtyPer) *selectUnitQtyPer;
      l_collectionPrice = colPrice;
      if(l_deliveryPrice ===0)  l_deliveryPrice =unitPrice;
      if(specialprice!==0 && specialprice < l_deliveryPrice)  l_deliveryPrice=specialprice
      if(specialprice!==0 && specialprice < l_collectionPrice)  l_collectionPrice=specialprice
      return {l_collectionPrice,l_deliveryPrice}
    } else {
      return {l_deliveryPrice:0,l_deliveryPrice:0}
    }
  };
  const getSpecialPrice = async (product)=>{
    let g_specialprice=0
    product.specialprices !== "" && await Promise.all(
      product.specialprices.map(specialprice=>{
        if (specialprice.unit === product.salesunit) g_specialprice = parseFloat(specialprice.unitprice);
      })
    )
    return g_specialprice
  }
  const onChange = async (e) => {
    const userInput = e.currentTarget.value;
    const filterKeys= userInput.split(" ")
  
    setUserInput(userInput);
    // Filter our suggestions that don't contain the user's input
    await getSearchProductsProduct(userInput)
    /*
    const filteredSuggestions = props.allProducts.filter(
      function (product) {
        let test = false
        filterKeys.map((key,index)=>{
          if(index===0) test= product.description.toLowerCase().indexOf(key.toLowerCase()) > -1
          else
            if(test) test= product.description.toLowerCase().indexOf(key.toLowerCase()) > -1
        })
        if (this.count < 10 && test) {
          this.count++;
          return true;
        }
        return false;
      },
      { count: 0 });
    setActiveSuggestion(-1);
    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(true);
    setUserInput(e.currentTarget.value);
    */
  };
  const getSearchProductsProduct = async (filter) => {
    console.log(filter);
    let url = `${API_URL}/products/search?filter=${filter}`
    await fetch(url)
      .then((res) => res.json())
      .then( async (res) => {
        let list = []
        setActiveSuggestion(-1);
        await Promise.all(
          res.message.data.map(async (product)=>{
          let units = await initialUnit(product)
          let sp = await getSpecialPrice(product)
          let pricelement = await initialPrice(product,units,sp)
          product.priceElement={colPrice:pricelement.l_collectionPrice,delPrice:pricelement.l_deliveryPrice}
          list.push(product)
        }))
        setFilteredSuggestions(list);
        setShowSuggestions(true)
      }).catch(ex=>{
        setFilteredSuggestions([]);
        setShowSuggestions(true)
      })

  }
  const onClick = (suggestion) => {
    setActiveSuggestion(-1);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(suggestion.description);
    let desc  = suggestion.description.replace(/ /g,"-")
    desc = desc.replace(/%/g, "");
    desc = desc.replace('/', "");
    navigate(`/route=product/card/${suggestion.itemno}-${desc}`);
    
  };
  const onViewMore = () => {
    setActiveSuggestion(-1);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    navigate("/route=search?filter=" + userInput)
  };
  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setShowSuggestions(false);
      if (activeSuggestion === -1)
        navigate("/route=search?filter=" + userInput);
      else {
        setUserInput(filteredSuggestions[activeSuggestion].description);
        let desc  = filteredSuggestions[activeSuggestion].description.replace(/ /g,"-")
        desc = desc.replace(/%/g, "");
        desc = desc.replace('/', "");
        navigate(
          `/route=product/card/${filteredSuggestions[activeSuggestion].itemno}-${desc}`
        );
      }
      setActiveSuggestion(-1);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      //if (activeSuggestion === 0) {
        //setActiveSuggestion(activeSuggestion - 1);
      //}
      setActiveSuggestion(activeSuggestion - 1);

      (element.current.clientHeight) && listRef.current.scrollBy(0, (-1*(element.current.clientHeight)))
    
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
     // debugger
      if (activeSuggestion + 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
      (element.current.clientHeight) && listRef.current.scrollBy(0, element.current.clientHeight)
    }
  };
  const replaceImage = (error) => {
    error.target.src = defaultImage; 
  }
  const renderSuggestionsListComponent = () => {
    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <Suggestions ref={listRef}>
            <SuggestionHeader>
              Products
            </SuggestionHeader>
            {filteredSuggestions.map(  (suggestion, index) => {
              return index === activeSuggestion ? (
                <SuggestionsActiveLi
                  
                  key={index}
                  onClick={() => onClick(suggestion)}
                >
                  <div
                    
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image ref={element} 
                    src={`/${suggestion.image}`} 
                    alt={`/${suggestion.description}`}
                    onError={replaceImage}
                    />
                    <div>
                      <Description>{suggestion.description}</Description>
                      <Unit>Unit : {suggestion.salesunit}</Unit>
                      {(props.settings.pricevisible && props.currentUser.id) &&  <Price>Collection : £{suggestion.priceElement.colPrice.toFixed(2)} - Delivery : £{suggestion.priceElement.delPrice.toFixed(2)}</Price> }
                    </div>
                  </div>
                </SuggestionsActiveLi>
              ) : (
                <SuggestionsLi  key={index} onClick={() => onClick(suggestion)}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image ref={element} 
                    src={`/${suggestion.image}`} 
                    alt={`/${suggestion.description}`}
                    onError={replaceImage}
                    />
                    <div>
                      <Description>{suggestion.description}</Description>
                      <Unit>Unit : {suggestion.salesunit}</Unit>
                      {(props.settings.pricevisible && props.currentUser.id) &&  <Price>Collection : £{suggestion.priceElement.colPrice.toFixed(2)} - Delivery : £{suggestion.priceElement.delPrice.toFixed(2)}</Price> }
                    </div>
                  </div>
                </SuggestionsLi>
              );
            })}
            <CartButtons>
              <CartButton onClick={onViewMore}>View More</CartButton>
            </CartButtons>
          </Suggestions>
        );
      } else {
        suggestionsListComponent = <NoSuggestions></NoSuggestions>;
      }
    }
    return suggestionsListComponent;
  };
  return (
    <Fragment>
      
      <SearchInput
        ref={searchInputRef}
        type="text"
        onChange={async (e)=>await onChange(e)}
        onKeyDown={onKeyDown}
        value={userInput}
      ></SearchInput>
      {userInput !== "" ? (
        <ClearButton
          onClick={() => {
            setUserInput("");
            searchInputRef.current.focus();
          }}
        >
          <ClearButtonText>X</ClearButtonText>
        </ClearButton>
      ) : (
        <ClearButton>
          <ClearButtonTextNon>_</ClearButtonTextNon>
        </ClearButton>
      )}
      <SearchButton
        onClick={() => {
          navigate("/route=search?filter=" + userInput);
          setShowSuggestions(false);
          setActiveSuggestion(-1);
        }}
      >
        <Search
          style={{
            color: colors.blackColor,
            width: "26px",
            marginRight: "5px",
          }}
        />
        SEARCH
      </SearchButton>
      {renderSuggestionsListComponent()}
    </Fragment>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getAllProducts: bindActionCreators(
        productActions.getSearchProducts,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    allProducts: state.allProductListReducer,
    settings: state.settingReducer,
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Autocomplete);

