import styled from "styled-components";
import { colors } from "../res/values/values";
import FooterMaintenace from "../components/FooterMaintenace";
import HeaderMaintence from "../components/HeaderMaintence";

import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
const Container = styled.div`
  min-height: ${(props) => props.height}px;
  min-width: ${(props) =>  props.width >1780 ? "1780px" : `${props.width}px`};
`;
const MessageContainer = styled.div`
  min-height: 40vw;
  display:flex ;
  flex-direction:column ;
  flex:1 ;
  align-items:center ;
  padding:2rem ;
`;
const Label = styled.h1`
  font-size: 3rem;
  color: ${colors.primaryColor};
  font-weight: 700;
  text-align:center ;
`;
const Text = styled.span`
  font-size: 2rem;
  color: black;
  font-weight: 600;
  text-align:center ;
`;
function Maintenance() {
  const { height,width } = useWindowWidthAndHeight();
  return (
    <Container height={height} width={width}>
      <HeaderMaintence />
      <MessageContainer>
        <Label>Maintenance Mode</Label>
        <Text>
          We are currently performing some scheduled maintenance. We will be
          back as soon as possible. Please check back soon.
        </Text>
      </MessageContainer>
      <FooterMaintenace />
    </Container>
  );
}

export default Maintenance;
